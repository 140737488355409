



































































































































































































































































































































import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { projects } from '@/views/smart-sports/data'
import router from '@/router'

export default defineComponent({
  name: 'SmartSportsPhone',
  components: {
    swiper,
    swiperSlide
  },
  setup() {
    const showState = reactive({
      gzlys: false,
      gzlzy: false
    })
    const handleSetShow = (type: string) => {
      showState[type] = true
    }
    function go2detail(type: any, id: any) {
      router.push(`/zhty/${type}/detail/${id}`)
    }
    return {
      ...toRefs(showState),
      handleSetShow,
      projects,
      swiperOption: {
        pagination: { el: '.swiper-pagination' },
        autoplay: false
      },
      go2detail
    }
  }
})
